/**
 * Import modules
 */
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FaAsterisk } from 'react-icons/fa';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';

/**
 * Import components
 */
import Buttons from '../../Buttons/Buttons';
import Message from '../../Message/Message';

/**
 * Import contexts
 */
import { useLoadingContext } from '../../../contexts/loadingContext';
import { useSubventionContext } from '../../../contexts/subventionContext';
import { useUserContext } from '../../../contexts/userContext';
import { useDashboardContext } from '../../../contexts/DashboarContext';

/**
 * Import locals
 */
import './DuplicateSubventionModal.scss';
import { addSubvention } from '../../../requests/SubventionRequests';
import utility from '../../../utility';
import contents from '../../../contents/contents.json';

const DuplicateSubventionModal = () => {
	const {
		actions: { setter },
	} = useLoadingContext();
	const {
		duplicatedSubvention,
		subventionList,
		isNewDuplicateValid,
		subventionListSetter,
		actions: { setDuplicatedSubvention, editCancel },
	} = useSubventionContext();
	const {
		actions: { switchShowDuplicateModal },
	} = useDashboardContext();
	const { init } = useUserContext();

	let history = useHistory();

	const [message, setMessage] = useState('');

	const handleStartDate = async (evt) => {
		const assosPart = `${duplicatedSubvention.association.name.slice(0, 3)}`;
		const year = moment(evt.target.value).year().toString().slice(2, 4);
		const month = moment(evt.target.value).month() + 1;
		const datePart = `${year}${month < 10 ? `0${month}` : month}`;
		const refStart = `${assosPart}-${datePart}`;

		const filterSameRef = subventionList
			.filter((sub) => {
				return sub.ref.startsWith(refStart);
			})
			.map((sub) => parseInt(sub.ref.replace(refStart, '')));

		const fullRef = `${refStart}-${filterSameRef.length + 1}`;

		setDuplicatedSubvention({
			...duplicatedSubvention,
			ref: fullRef,
			start_date: evt.target.value,
		});
		setMessage('');
	};

	const handleEndDate = async (evt) => {
		const { duration, nDuration } = utility.getDurations(
			duplicatedSubvention.start_date,
			evt.target.value
		);

		await setDuplicatedSubvention({
			...duplicatedSubvention,
			end_date: evt.target.value,
			duration,
			n_duration: nDuration,
			expense_reciepts_date: evt.target.value,
			balance_submission_date: evt.target.value,
		});
		setMessage('');
	};

	console.log(
		'[DuplicateSubventionModal.jsx]{L:93} duplicatedSubvention: ',
		duplicatedSubvention
	);
	const handleSubmit = async (evt) => {
		evt.preventDefault();

		const itemToSend = {
			...duplicatedSubvention,
			status: duplicatedSubvention.amount_contracted
				? contents.status.statusAccepted
				: contents.status.statusAsked,
			// Ref: duplicatedSubvention.ref,
			accountingProduct: duplicatedSubvention.accounting_product.id,
			accountingTiers: duplicatedSubvention.accounting_tier.id,
			amountContracted: duplicatedSubvention.amount_contracted,
			askedAmount: duplicatedSubvention.amount_asked,
			associationId: duplicatedSubvention.association.id,
			balanceRemiderDate: duplicatedSubvention.balance_submission_date,
			balanceReminderTime: duplicatedSubvention.balance_submission_time_limit,
			counted: false,
			documents: [],
			duration: duplicatedSubvention.duration,
			endDate: duplicatedSubvention.end_date,
			etat: contents.etat.etatPending,
			expenseRemiderDate: duplicatedSubvention.expense_reciepts_date,
			expenseReminderTime: duplicatedSubvention.expense_reciepts_time_limit,
			giveBack: duplicatedSubvention.giveBack,
			nDuration: duplicatedSubvention.n_duration,
			payments: [],
			remains: duplicatedSubvention.remains,
			startDate: duplicatedSubvention.start_date,
			subventionFunder: duplicatedSubvention.funder.id,
			subventionManager: duplicatedSubvention.manager.id,
			subventionName: duplicatedSubvention.name,
			subventionType: duplicatedSubvention.subvention_type_id,
			totalRecieved: duplicatedSubvention.total_recieved,
			versement: duplicatedSubvention.versement,
		};
		delete itemToSend.id;

		setter(true, true);
		const result = await addSubvention(itemToSend);

		switch (result.status) {
			case 200:
				await subventionListSetter([...subventionList, result.data]);
				switchShowDuplicateModal();

				history.push(
					`/dashboard/subventions/${result.data.name}?id=${result.data.id}`
				);
				break;

			case 500:
				switch (result.message) {
					case 'RangeError: Invalid time value':
						setMessage(contents.messages.invalidTime);
						break;

					default:
						setMessage(result.message);
						break;
				}

				break;

			default:
				setMessage(result.message);
				break;
		}
		setter(false, true);
	};

	const cancelEdit = () => {
		editCancel();
		switchShowDuplicateModal();
		setMessage('');
	};

	const handleInputChange = async (evt) => {
		if (evt.target.localName === 'select') {
			const test = {
				id: parseFloat(evt.target.value),
				name: evt.target.selectedOptions[0].text,
			};
			setDuplicatedSubvention({
				...duplicatedSubvention,
				[evt.target.id]: test,
			});
		} else {
			if (isNaN(evt.target.value) || evt.target.value === '') {
				setDuplicatedSubvention({
					...duplicatedSubvention,
					[evt.target.id]: evt.target.value,
				});
			} else {
				setDuplicatedSubvention({
					...duplicatedSubvention,
					[evt.target.id]: parseFloat(evt.target.value),
				});
			}
		}
	};

	const handleChangeAmountChange = async (values, sourcesInfos) => {
		if (sourcesInfos.event) {
			const { floatValue } = values;

			await setDuplicatedSubvention({
				...duplicatedSubvention,
				[sourcesInfos.event.target.id]: floatValue,
			});
		}
		setMessage('');
	};

	let subventionTypeHTML,
		funderHTML,
		managersHTML = <></>;

	if (init.managers) {
		subventionTypeHTML = init.Subvention_typeList.map((type, i) => (
			<option key={`${type.id}-${i}`} value={type.id}>
				{type.name}
			</option>
		));

		funderHTML = init.FunderList.map((funder, i) => (
			<option key={`${funder.id}-${i}`} value={funder.id}>
				{funder.name}
			</option>
		));

		managersHTML = init.managers.map((manager, i) => (
			<option
				key={`${manager.id}-${i}`}
				value={manager.id}
			>{`${manager.first_name} ${manager.last_name}`}</option>
		));
	}

	return (
		<div className={'DuplicateSubventionModal'}>
			<Form
				key={'e9187c2f-f2b0-4986-9193-11c56b060cbe'}
				onSubmit={handleSubmit}
				noValidate
			>
				{/*<Form.Group className={'form-group form-ref'} controlId={'Ref'}>*/}
				{/*	<Form.Label>{contents.labels.ref}</Form.Label>*/}
				{/*	<div>{duplicatedSubvention.ref}</div>*/}
				{/*</Form.Group>*/}

				<section className={'section-grid'}>
					<Form.Group
						className={'form-group form-sub-type'}
						controlId={'subventionType'}
					>
						<Form.Label column={true}>
							{contents.labels.subventionType}
						</Form.Label>
						<Form.Select
							onChange={handleInputChange}
							value={duplicatedSubvention?.subventionType?.id}
						>
							{subventionTypeHTML}
						</Form.Select>
					</Form.Group>

					<Form.Group className={'form-group form-sub-name'} controlId={'name'}>
						<Form.Label column={true}>
							{contents.labels.subventionName}
						</Form.Label>
						<Form.Control
							required
							type={'text'}
							value={duplicatedSubvention.name}
							onChange={handleInputChange}
						/>
						<FaAsterisk className={'asterisk'} size={'7px'} color={'red'} />
					</Form.Group>
				</section>

				<section className={'section-grid'}>
					<Form.Group className={'form-group form-funder'} controlId={'funder'}>
						<Form.Label column={true}>
							{contents.labels.subventionFunder}
						</Form.Label>
						<Form.Select
							onChange={handleInputChange}
							value={duplicatedSubvention?.funder?.id}
							required
						>
							{funderHTML}
						</Form.Select>
						<FaAsterisk className={'asterisk'} size={'7px'} color={'red'} />
					</Form.Group>

					{/*<Form.Group*/}
					{/*	className={'form-group form-funder_ref'}*/}
					{/*	controlId={'funder_ref'}*/}
					{/*>*/}
					{/*	<Form.Label>{contents.labels.funder_ref}</Form.Label>*/}
					{/*	<Form.Control*/}
					{/*		onChange={handleInputChange}*/}
					{/*		value={duplicatedSubvention?.funder_ref}*/}
					{/*	/>*/}
					{/*</Form.Group>*/}

					<Form.Group
						className={'form-group form-manager'}
						controlId={'manager'}
					>
						<Form.Label column={true}>
							{contents.labels.subventionManager}
						</Form.Label>
						<Form.Select
							onChange={handleInputChange}
							value={duplicatedSubvention?.manager?.id}
						>
							{managersHTML}
						</Form.Select>
					</Form.Group>
				</section>

				<section className={'section-grid'}>
					<Form.Group
						className={'form-group form-amount_asked'}
						controlId={'amount_asked'}
					>
						<Form.Label column={true}>{contents.labels.askedAmount}</Form.Label>
						<NumericFormat
							value={duplicatedSubvention.amount_asked}
							id={'amount_asked'}
							displayType={'input'}
							thousandSeparator={' '}
							suffix={' €'}
							className="totaux-read"
							decimalScale={2}
							onValueChange={handleChangeAmountChange}
							allowNegative={false}
							data-section={'subFinancialInfos'}
						/>
					</Form.Group>

					<Form.Group
						className={'form-group form-amount_contracted'}
						controlId={'amount_contracted'}
					>
						<Form.Label column={true}>
							{contents.labels.contractedAmount}
						</Form.Label>
						<NumericFormat
							value={duplicatedSubvention.amount_contracted}
							id={'amount_contracted'}
							displayType={'input'}
							thousandSeparator={' '}
							suffix={' €'}
							className="totaux-read"
							decimalScale={2}
							onValueChange={handleChangeAmountChange}
							allowNegative={false}
							data-section={'subFinancialInfos'}
						/>
					</Form.Group>
				</section>

				<section className={'section-grid'}>
					<Form.Group
						className={'form-group form-start-date'}
						controlId={'start_date'}
					>
						<Form.Label column={true}>{contents.labels.from}</Form.Label>
						<input
							id={'startDate'}
							type="date"
							onChange={handleStartDate}
							value={duplicatedSubvention.start_date}
						/>
						<FaAsterisk className={'asterisk'} size={'7px'} color={'red'} />
					</Form.Group>

					<Form.Group
						className={'form-group form-end-date'}
						controlId={'end_date'}
					>
						<Form.Label column={true}>{contents.labels.to}</Form.Label>
						<input
							id={'endDate'}
							type="date"
							onChange={handleEndDate}
							min={duplicatedSubvention.start_date}
							value={duplicatedSubvention.end_date}
						/>
						<FaAsterisk className={'asterisk'} size={'7px'} color={'red'} />
					</Form.Group>
				</section>

				<section className={'section-grid'}>
					<Form.Group
						className={'form-duration'}
						controlId={'subventionDuration'}
					>
						<Form.Label column={true}>{contents.labels.duration}</Form.Label>

						<div className={'duration-time'}>
							{duplicatedSubvention.duration}
						</div>
						<div className={'duration-type'}>
							{contents.labels.durationType}
						</div>
					</Form.Group>
				</section>

				{message && (
					<section className={'section-grid'}>
						<Message status={'error'} message={message} />
					</section>
				)}

				<section className={'section-grid section-buttons'}>
					<div className="buttons">
						<Buttons
							submitTitle={contents.buttons.duplicate}
							handleCancel={cancelEdit}
							customCSS={'submit'}
							isDisabled={!isNewDuplicateValid}
						/>
					</div>
				</section>
			</Form>
		</div>
	);
};

export default DuplicateSubventionModal;
