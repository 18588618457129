/**
 * Import modules
 */
import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FaAsterisk } from 'react-icons/fa';

import moment from 'moment';
import { NumericFormat } from 'react-number-format';

/**
 * Import components
 */
import Message from '../../Message/Message';
import Buttons from '../../Buttons/Buttons';

/**
 * Import Contexts
 */
import { useLoadingContext } from '../../../contexts/loadingContext';
import { useSubventionContext } from '../../../contexts/subventionContext';
import { useUserContext } from '../../../contexts/userContext';
import { useDashboardContext } from '../../../contexts/DashboarContext';

/**
 * Import locals
 */
import './CreateSubModal.scss';
import { addSubvention } from '../../../requests/SubventionRequests';
import utility from '../../../utility';
import contents from '../../../contents/contents.json';

const CreateSubventionModal = ({ setEdited }) => {
	const {
		actions: { setter },
	} = useLoadingContext();
	const { subventionList, subventionListSetter } = useSubventionContext();
	const {
		newSubvention,
		setNewSubvention,
		cancelCreate,
		showCreateSubventionModal,
		NewSubventionMeetRequierements,
		actions: { switchShowCreateModal },
	} = useDashboardContext();
	const { user, init } = useUserContext();

	let history = useHistory();

	const [validated, setValidated] = useState(false);

	const [message, setMessage] = useState('');

	const handleStartDate = async (evt) => {
		const assosPart = `${user.assos[0].name.slice(0, 3)}`;
		const year = moment(evt.target.value).year().toString().slice(2, 4);
		const month = moment(evt.target.value).month() + 1;
		const datePart = `${year}${month < 10 ? `0${month}` : month}`;
		const refStart = `${assosPart}-${datePart}`;

		const filterSameRef = subventionList
			.filter((sub) => {
				return sub.ref.startsWith(refStart);
			})
			.map((sub) => parseInt(sub.ref.replace(refStart, '')));

		const fullRef = `${refStart}-${filterSameRef.length + 1}`;

		setNewSubvention({
			...newSubvention,
			ref: fullRef,
			startDate: evt.target.value,
		});
		setMessage('');
	};

	const handleEndDate = async (evt) => {
		const { duration, nDuration } = utility.getDurations(
			newSubvention.startDate,
			evt.target.value
		);

		await setNewSubvention({
			...newSubvention,
			endDate: evt.target.value,
			duration,
			nDuration,
		});
		setMessage('');
	};

	const handleAskedAmountChange = (values, sourcesInfos) => {
		if (sourcesInfos.event) {
			const { value } = values;
			setNewSubvention({
				...newSubvention,
				[sourcesInfos.event.target.id]: parseInt(value),
			});
		}
		setMessage('');
	};

	const handleContractedAmountChange = (values, sourcesInfos) => {
		if (sourcesInfos.event) {
			const { value } = values;
			setNewSubvention({
				...newSubvention,
				[sourcesInfos.event.target.id]: parseInt(value),
			});
		}
		setMessage('');
	};

	const handleInputChange = async (evt) => {
		setNewSubvention({ ...newSubvention, [evt.target.id]: evt.target.value });

		// if (isNaN(evt.target.value) || evt.target.value === '') {
		// 	setNewSubvention({ ...newSubvention, [evt.target.id]: evt.target.value });
		// } else {
		// 	setNewSubvention({
		// 		...newSubvention,
		// 		[evt.target.id]: parseFloat(evt.target.value),
		// 	});
		// }
		setMessage('');
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!NewSubventionMeetRequierements) {
			setMessage(contents.messages.meetRequirement);
			event.stopPropagation();
		} else {
			setValidated(true);
			const itemToSend = {
				...newSubvention,
				associationId: user.assos[0].id,
				startDate: newSubvention.startDate,
				endDate: newSubvention.endDate,
				status: newSubvention.contractedAmount
					? contents.status.statusAccepted
					: contents.status.statusAsked,
				versement: 'Non versée',
				etat: newSubvention.contractedAmount
					? contents.etat.etatInProgress
					: contents.etat.etatPending,
				counted: false,
				payments: [],
				documents: [],
				accountingProduct: init.Accounting_productList[0].id,
				accountingTiers: init.Accounting_tiersList[0].id,
				askedAmount: newSubvention.askedAmount,
				amountContracted: newSubvention.contractedAmount,
				totalRecieved: 0,
				remains: newSubvention.contractedAmount,
				giveBack: 0,
				expenseReminderTime: 1,
				balanceReminderTime: 1,
				expenseRemiderDate: newSubvention.endDate,
				balanceRemiderDate: newSubvention.endDate,
				internalRef: newSubvention.internalRef,
				description: newSubvention.description,
			};

			setter(true, true);
			const result = await addSubvention(itemToSend);

			switch (result.status) {
				case 200:
					await subventionListSetter([...subventionList, result.data]);

					setNewSubvention({
						subventionType: init.Subvention_typeList[0].id,
						subventionFunder: init.FunderList[0].id,
						subventionManager: init.managers[0].id,
						ref: contents.placeholders.newSubventionRef,
						subventionName: null,
						startDate: '',
						endDate: '',
						subventionDuration: null,
						askedAmount: null,
						contractedAmount: null,
						internalRef: '',
						description: '',
					});

					setValidated(false);
					await setEdited(true);

					switchShowCreateModal('subventions');

					history.push(
						`/dashboard/subventions/${result.data.name}?id=${result.data.id}`
					);
					break;

				case 500:
					switch (result.message) {
						case 'RangeError: Invalid time value':
							setMessage(contents.messages.invalidTime);
							break;

						default:
							setMessage(result.message);
							break;
					}

					break;

				default:
					setMessage(result.message);
					break;
			}
			setter(false, true);
		}
	};

	let subventionTypeHTML,
		funderHTML,
		managersHTML = <></>;

	if (init.managers) {
		subventionTypeHTML = init.Subvention_typeList.map((type, i) => (
			<option key={`${type.id}-${i}`} value={type.id}>
				{type.name}
			</option>
		));

		funderHTML = init.FunderList.map((funder, i) => (
			<option key={`${funder.id}-${i}`} value={funder.id}>
				{funder.name}
			</option>
		));

		managersHTML = init.managers.map((manager, i) => (
			<option key={`${manager.id}-${i}`} value={manager.id}>
				{`${manager.first_name} ${manager.last_name}`}
			</option>
		));
	}

	/**
	 * Build new subvention referencie
	 * Trigger: init, startDate
	 */

	useEffect(() => {
		if (init.Subvention_typeList[0] && init.FunderList[0] && init.managers[0]) {
			setNewSubvention({
				...newSubvention,
				subventionType:
					newSubvention.subventionType === null
						? init.Subvention_typeList[0].id
						: newSubvention.subventionType,
				subventionFunder:
					newSubvention.subventionFunder === null
						? init.FunderList[0].id
						: newSubvention.subventionFunder,
				subventionManager:
					newSubvention.subventionManager === null
						? init.managers[0].id
						: newSubvention.subventionManager,
				associationId:
					newSubvention.associationId === null
						? user.assos[0].id
						: newSubvention.associationId,
			});
		}
	}, [init, showCreateSubventionModal]);

	return (
		<Modal
			key={'b640e24c-301b-11ec-8d3d-0242ac130003'}
			show={showCreateSubventionModal}
			onHide={() => switchShowCreateModal('subventions')}
			size={'xl'}
			aria-labelledby={'modals-content-option'}
			centered
			restoreFocus
			dialogClassName={'add-sub'}
		>
			<Modal.Header closeButton>
				<Modal.Title>{contents.titles.CreateSubventionModal}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form
					key={'e9187c2f-f2b0-4986-9193-11c56b060cbe'}
					onSubmit={handleSubmit}
					noValidate
					validated={validated}
					className={'CreateSubventionModal'}
				>
					{/*<Form.Group className={'form-group form-ref'} controlId={'ref'}>*/}
					{/*	<Form.Label>{contents.labels.ref}</Form.Label>*/}
					{/*	<div>{newSubvention.ref}</div>*/}
					{/*</Form.Group>*/}

					<section className={'section-grid'}>
						<Form.Group
							className={'form-group form-internal-ref'}
							controlId={'internalRef'}
						>
							<Form.Label column={true}>Réference interne :</Form.Label>
							<Form.Control
								required
								type={'text'}
								value={newSubvention.internalRef}
								onChange={handleInputChange}
							/>
						</Form.Group>

						<Form.Group
							className={'form-group form-sub-type'}
							controlId={'subventionType'}
						>
							<Form.Label column={true}>
								{contents.labels.subventionType}
							</Form.Label>
							<Form.Select
								onChange={handleInputChange}
								value={newSubvention.subventionType}
							>
								{subventionTypeHTML}
							</Form.Select>
						</Form.Group>
					</section>

					<section className={'section-grid'}>
						<Form.Group
							className={'form-group form-sub-type'}
							controlId={'subventionType'}
						>
							<Form.Label column={true}>
								{contents.labels.subventionType}
							</Form.Label>
							<Form.Select
								onChange={handleInputChange}
								value={newSubvention.subventionType}
							>
								{subventionTypeHTML}
							</Form.Select>
						</Form.Group>

						<Form.Group
							className={'form-group form-sub-name'}
							controlId={'subventionName'}
						>
							<Form.Label column={true}>
								{contents.labels.subventionName}
							</Form.Label>
							<Form.Control
								required
								type={'text'}
								value={newSubvention.subventionName}
								onChange={handleInputChange}
							/>
							<FaAsterisk className={'asterisk'} size={'7px'} color={'red'} />
						</Form.Group>
					</section>

					<section className={'section-grid'}>
						<Form.Group
							className={'form-group form-funder'}
							controlId={'subventionFunder'}
						>
							<Form.Label column={true}>
								{contents.labels.subventionFunder}
							</Form.Label>
							<Form.Select
								onChange={handleInputChange}
								value={newSubvention.subventionFunder}
								required
							>
								{funderHTML}
							</Form.Select>
							<FaAsterisk className={'asterisk'} size={'7px'} color={'red'} />
						</Form.Group>

						<Form.Group
							className={'form-group form-manager'}
							controlId={'subventionManager'}
						>
							<Form.Label column={true}>
								{contents.labels.subventionManager}
							</Form.Label>
							<Form.Select
								onChange={handleInputChange}
								value={newSubvention.subventionManager}
							>
								{managersHTML}
							</Form.Select>
						</Form.Group>
					</section>

					<section className={'section-grid'}>
						<Form.Group
							className={'form-group form-askedAmount'}
							controlId={'askedAmount'}
						>
							<Form.Label column={true}>
								{contents.labels.askedAmount}
							</Form.Label>
							<NumericFormat
								value={newSubvention.askedAmount}
								id={'askedAmount'}
								displayType={'input'}
								thousandSeparator={' '}
								suffix={' €'}
								className="totaux-read"
								decimalScale={2}
								onValueChange={handleAskedAmountChange}
								allowNegative={false}
								data-section={'subFinancialInfos'}
							/>
						</Form.Group>

						<Form.Group
							className={'form-group form-contractedAmount'}
							controlId={'subventionContractedAmount'}
						>
							<Form.Label column={true}>
								{contents.labels.contractedAmount}
							</Form.Label>
							<NumericFormat
								value={newSubvention.contractedAmount}
								id={'contractedAmount'}
								displayType={'input'}
								thousandSeparator={' '}
								suffix={' €'}
								className="totaux-read"
								decimalScale={2}
								onValueChange={handleContractedAmountChange}
								allowNegative={false}
								data-section={'subFinancialInfos'}
							/>
						</Form.Group>
					</section>

					<section className={'section-grid'}>
						<Form.Group
							className={'form-description'}
							controlId={'description'}
						>
							<Form.Label column={true}>Description :</Form.Label>
							<Form.Control
								required
								as={'textarea'}
								type={'text'}
								rows={3}
								value={newSubvention.description}
								onChange={handleInputChange}
							/>
						</Form.Group>
					</section>

					<section className={'section-grid'}>
						<Form.Group
							className={'form-group form-start-date'}
							controlId={'subventionStartDate'}
						>
							<Form.Label column={true}>{contents.labels.from}</Form.Label>
							<input
								id={'startDate'}
								type="date"
								onChange={handleStartDate}
								value={newSubvention.startDate}
							/>
							<FaAsterisk className={'asterisk'} size={'7px'} color={'red'} />
						</Form.Group>

						<Form.Group
							className={'form-group form-end-date'}
							controlId={'subventionEndDate'}
						>
							<Form.Label column={true}>{contents.labels.to}</Form.Label>
							<input
								id={'endDate'}
								type="date"
								onChange={handleEndDate}
								min={newSubvention.startDate}
								value={newSubvention.endDate}
							/>
							<FaAsterisk className={'asterisk'} size={'7px'} color={'red'} />
						</Form.Group>
					</section>

					<section className={'section-grid'}>
						<Form.Group
							className={'form-duration'}
							controlId={'subventionDuration'}
						>
							<Form.Label column={true}>{contents.labels.duration}</Form.Label>

							<div className={'duration-time'}>{newSubvention.duration}</div>
							<div className={'duration-type'}>
								{contents.labels.durationType}
							</div>
						</Form.Group>
					</section>

					{message && (
						<section className={'section-grid'}>
							<Message status={'error'} message={message} />
						</section>
					)}

					<section className={'section-grid section-buttons'}>
						<div className="buttons">
							<Buttons handleCancel={cancelCreate} />
						</div>
					</section>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default CreateSubventionModal;
